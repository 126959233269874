import Phaser from 'phaser';
import KineticScrollingRemakePlugin from 'src/pages/dressUp/KineticScrollingRemakePlugin';
import ItemManager from 'src/pages/dressUp/ItemManager';
import { soundType } from 'src/pages/dressUp/types';
import ControlsManager from 'src/pages/dressUp/ControlsManager';
import { updateReceipt } from 'src/utils/purchaseUtils';

export default class CategoryManager extends Phaser.GameObjects.Group {
  private readonly kineticScrolling: KineticScrollingRemakePlugin;
  private clickSound: soundType;
  private readonly itemManager: ItemManager;
  private readonly controlManager: ControlsManager;
  scene: Phaser.Scene;
  private lastClickedItem = '';
  public constructor(
    scene: Phaser.Scene,
    womenGroup: Phaser.GameObjects.Group,
    kineticScrollingPlugin: KineticScrollingRemakePlugin,
    clickSound: soundType,
  ) {
    super(scene);
    this.scene = scene;
    this.kineticScrolling = kineticScrollingPlugin;
    this.clickSound = clickSound;
    this.itemManager = new ItemManager(scene, womenGroup, this.kineticScrolling, clickSound);
    const cameraSound = this.scene.sound.add('camera');
    this.controlManager = new ControlsManager(
      scene,
      kineticScrollingPlugin,
      clickSound,
      cameraSound,
      womenGroup,
      this.itemManager,
    );
    this.scene.registry.set('controlsManager', this.controlManager);
    this.lastClickedItem = '';
  }

  // Setting up control functions
  setupScaling(item: Phaser.GameObjects.Sprite) {
    item.setScale(1);
    this.scene.cameras.main.scrollX = 0;
    this.scene.time.delayedCall(200, () => {
      item?.setScale(0.8);
    });
  }

  public getCategoryPrice(cat: string) {
    const prices = {
      '1': { key: '12$', value: 12, name: 'Glasses' },
      '2': { key: '15$', value: 15, name: 'Dresses' },
      '3': { key: '10$', value: 10, name: 'Shirt' },
      '4': { key: '7$', value: 7, name: 'Skirt' },
      '5': { key: '8$', value: 8, name: 'Trousers' },
      '6': { key: '11$', value: 11, name: 'Shoes' },
      '7': { key: '5$', value: 5, name: 'Hair' },
      '8': { key: '10$', value: 10, name: 'Bodies' },
      '9': { key: '8$', value: 8, name: 'Eyebrows' },
      '10': { key: '6$', value: 6, name: 'Eyes' },
      '11': { key: '9$', value: 9, name: 'Lips' },
    };
    return prices[cat as keyof typeof prices];
  }

  onCategoryClicked(clickedItem: Phaser.GameObjects.Sprite) {
    this.clickSound.play();

    // Configure kinetic scrolling
    this.kineticScrolling.configure({
      horizontalScroll: false,
      kineticMovement: false,
    });
    this.kineticScrolling.targetX = 0;
    this.scene.cameras.main.x = 0;

    // Scale effect on clicked item
    clickedItem.setScale(0.9);

    // Clear the current items from the itemGroup
    this.itemManager.clearItems();

    // Category configurations
    let itemCount = 2;
    let itemFolder = '';
    let index = 0;
    switch (clickedItem.frame.name) {
      case 'button/1.png':
        itemCount = 5;
        itemFolder = 'Glasses/';
        index = 1;
        break;
      case 'button/2.png':
        itemCount = 18;
        itemFolder = 'Dresses/';
        index = 2;
        break;
      case 'button/3.png':
        itemCount = 45;
        itemFolder = 'Top/';
        index = 3;
        break;
      case 'button/4.png':
        itemCount = 40;
        itemFolder = 'Skirt/';
        index = 4;
        break;
      case 'button/5.png':
        itemCount = 10;
        itemFolder = 'Trousers/';
        index = 5;
        break;
      case 'button/6.png':
        itemCount = 3;
        itemFolder = 'Shoes/';
        index = 6;
        break;
      case 'button/7.png':
        itemCount = 65;
        itemFolder = 'Hair/';
        index = 7;
        break;
      case 'button/8.png':
        itemCount = 7;
        itemFolder = 'Bodies/';
        index = 8;
        break;
      case 'button/9.png':
        itemCount = 12;
        itemFolder = 'Eyebrows/';
        index = 9;
        break;
      case 'button/10.png':
        itemCount = 30;
        itemFolder = 'Eyes/';
        index = 10;
        break;
      case 'button/11.png':
        itemCount = 30;
        itemFolder = 'Lips/';
        index = 11;
        break;
    }
    this.itemManager.renderCategoryItems(itemFolder, itemCount);
    const details = this.getCategoryPrice(`${index}`);
    if (this.lastClickedItem !== details.name) {
      updateReceipt(details.name, details.value);
    }
    this.lastClickedItem = details.name;
  }

  renderPrice(index: number, x: number, y: number) {
    const price = this.getCategoryPrice(`${index}`);
    const priceText = this.scene.add.text(x, y, price?.key || '$0', {
      font: '16px Arial',
      color: '#ffffff',
      align: 'center',
    });
    priceText.setOrigin(0.5);
    this.add(priceText);
  }

  public start() {
    let yPosition = 70;

    // render columns of category icons
    for (let i = 1; i < 12; i++) {
      const x = i < 7 ? this.scene.scale.width - 70 : 65;
      const priceX = i < 7 ? this.scene.scale.width - 125 : 120;
      const categoryIcon = this.scene.add
        .sprite(x, yPosition, 'dressup', `button/${i}.png`)
        .setInteractive({ cursor: 'pointer' });
      categoryIcon.setOrigin(0.5);
      categoryIcon.setScale(0.8);
      categoryIcon.on('pointerdown', () => this.onCategoryClicked.call(this, categoryIcon));
      categoryIcon.on('pointerup', () => this.setupScaling(categoryIcon));
      this.add(categoryIcon);
      this.renderPrice(i, priceX, yPosition);
      yPosition += 100;
      if (i === 6) {
        yPosition = 70;
      }
    }
    this.controlManager.renderControls();
  }
}

import React from 'react';
import './App.css';
import { RouterProvider } from 'react-router';
import router from 'src/routes/Root';
import Footer from 'src/components/Footer';

const App = () => {
  return (
    <div className="app">
      <header className="header">
        <h1 onClick={() => window.location.replace('/')}>Welcome to PlayJoz!</h1>
        <p>Enjoy our exciting online games.</p>
      </header>
      <RouterProvider router={router} />
      <Footer key="footer" />
    </div>
  );
};

export default App;

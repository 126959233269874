import QRCode from 'qrcode';

export const addQrCodeToImage = (base64Image: string, qrText: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = base64Image;
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the original image onto the canvas
      ctx?.drawImage(img, 0, 0);

      // Generate QR code and draw it onto a separate canvas
      const qrCodeCanvas = document.createElement('canvas');
      await QRCode.toCanvas(qrCodeCanvas, qrText, {
        width: 200,
      });

      // Draw the QR code onto the original canvas
      ctx?.drawImage(
        qrCodeCanvas,
        img.width - qrCodeCanvas.width - 10, // Positioning at the bottom right with margin
        img.height - qrCodeCanvas.height - 10,
      );

      // Resolve with the final image in base64 format
      resolve(canvas.toDataURL('image/jpeg'));
    };
  });
};

import { type FC } from 'react';
import { useAppSelector } from 'src/hooks/reduxHooks';

export const Receipt: FC = () => {
  const { recipient } = useAppSelector((state) => state.purchase);
  return (
    <div id="receipt" className="receipt-container">
      <h3>Receipt</h3>
      {recipient.items.length && (
        <div id="receipt-items">
          {recipient.items.map((item: any, index: number) => (
            <div key={index} className="receipt-item">
              <div className="receipt-item-name">{item.name}</div>
              <div className="receipt-item-quantity">x{item.quantity}</div>
              <div className="receipt-item-price">${item.price}</div>
            </div>
          ))}
        </div>
      )}
      <div className="receipt-total">Total: ${recipient.total}</div>
    </div>
  );
};

import JsPDF from 'jspdf';
import 'jspdf-autotable';
import QRCode from 'qrcode';

export const generatePDF = async (
  url: string,
  matches: number,
  hints: number,
  fastest: number,
  average: number,
) => {
  const doc = new JsPDF();

  // Add title
  doc.setFontSize(16);
  doc.text('Thank you for playing at PlayJoz', 10, 10);

  // Table data
  const headers = [['Found Matches', 'Hints Used', 'Quickest Match Time', 'Average Match Time']];
  const data = [[matches, hints, `${fastest} Sec.`, `${average} Sec.`]];

  // Add table
  doc.autoTable({
    startY: 20,
    head: headers,
    body: data,
    foot: [['Conclusion', '', '', 'Good Job']],
    didDrawPage: (data: any) => {
      // Add footer with page number
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      doc.setFontSize(10);
      doc.text(`Page ${doc.getNumberOfPages()}`, pageWidth / 2, pageHeight - 10, {
        align: 'center',
      });
    },
  });

  // Generate QR code
  let qrCodeData;
  try {
    qrCodeData = await QRCode.toDataURL(url);
  } catch (error) {
    console.error('Error generating QR code:', error);
    qrCodeData = '';
  }

  // Add QR code (adjust position if needed)
  if (qrCodeData) {
    const pageWidth = doc.internal.pageSize.getWidth();
    const qrSize = 50; // QR code size in mm
    doc.addImage(qrCodeData, 'PNG', pageWidth - qrSize - 60, 90, qrSize, qrSize);
  }

  // Generate PDF Blob
  const pdfBlob = doc.output('blob');
  return URL.createObjectURL(pdfBlob);
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'src/style/EmojiGame.css';
import { resetReceipt, updateReceipt } from 'src/utils/purchaseUtils';
import GsapModule from 'src/pages/Emoji/GsapModule';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { paymentSuccess, reportAlert } from 'src/utils/notificationController';
import ReportModule from 'src/pages/Emoji/ReportModule';
import { generatePDF } from 'src/utils/pdfUtils';
import GenericModal from 'src/components/payment/GenericModal';
import WrappedCheckoutForm from 'src/components/payment/CheckoutForm';
import { generateRandomString } from 'src/utils/utils';
import { createPaymentAction } from 'src/store/slices/purchase.slice';
import LoadingSpinner from 'src/components/loading/LoadingSpinner';

const timeLimit = 30 * 1000;
const priceFactor = 10;

const gsapModuleInstance = GsapModule.getInstance();
const reportModuleInstance = ReportModule.getInstance();

const EmojiGame = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const btnAreaRef = useRef<HTMLDivElement | null>(null);
  const hintBtnRef = useRef<HTMLDivElement | null>(null);
  const replayBtnRef = useRef<HTMLDivElement | null>(null);
  const intervalRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timeLeftRef = useRef(timeLimit);
  const [lastBtn, setLastBtn] = useState<HTMLElement | null>(null);
  const [hint, setHint] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const foundRef = useRef(0);
  const lintingRef = useRef(false);
  const msTilHintRef = useRef(5000);
  const [hintsCount, setHintsCount] = useState(0);
  const [matched, setMatched] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [orderId, setOrderId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { recipient } = useAppSelector((state) => state.purchase);

  useEffect(() => {
    setLastBtn(null);
    msTilHintRef.current = 5000;
    gsapModuleInstance.hidePrice();
    gsapModuleInstance.startGame(replayBtnRef, btnAreaRef, hintBtnRef, (newHint) => {
      reportModuleInstance.playedGame();
      setHint(newHint);
    });
  }, []);

  const onReplay = () => {
    reportModuleInstance.playedGame();
    reportModuleInstance.reset();
    resetReceipt();
    gsapModuleInstance.onReplay(
      replayBtnRef,
      btnAreaRef,
      hintBtnRef,
      () => {
        foundRef.current = 0;
        setLastBtn(null);
        timeLeftRef.current = timeLimit;
        msTilHintRef.current = 5000;
        setHintsCount(0);
        gsapModuleInstance.hidePrice();
        gsapModuleInstance.hideHint();
      },
      (newHint) => {
        setHint(newHint);
      },
    );
  };

  function updateTime() {
    gsapModuleInstance.updateTime(
      btnAreaRef,
      hintBtnRef,
      timeLeftRef.current,
      foundRef.current,
      hint,
      replayBtnRef,
      msTilHintRef.current,
      () => {
        timeLeftRef.current -= 10;
      },
      () => {
        msTilHintRef.current -= 10;
      },
      () => {
        if (intervalRef.current) clearInterval(intervalRef.current);
        intervalRef.current = null;
        msTilHintRef.current = 5000;
        timeLeftRef.current = timeLimit;
        setMatched(false);
        setTimeout(() => {
          gsapModuleInstance.hidePrice();
          gsapModuleInstance.hideHint();
        }, 100);
      },
    );
  }
  function btnClick(e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) {
    e.preventDefault();
    if (matched) return; //prevent user from repeatedly clicking the matching pair
    if (intervalRef.current === null) {
      //start timer upon first click
      intervalRef.current = setInterval(updateTime, 10);
    }
    const clickedBtn = e.currentTarget;
    gsapModuleInstance.clickEffect(clickedBtn);

    if (!!lastBtn && lastBtn.id !== clickedBtn.id) {
      if (clickedBtn.textContent === lastBtn?.textContent) {
        //matched
        reportModuleInstance.setLastMatch(new Date().valueOf());
        foundRef.current += 1;
        timeLeftRef.current += 3000;
        lintingRef.current = false;
        setMatched(true);
        gsapModuleInstance.afterMatching(
          btnAreaRef,
          hintBtnRef,
          foundRef.current,
          lastBtn,
          clickedBtn,
          (newHint) => {
            setHint(newHint);
            setMatched(false);
            timeLeftRef.current = timeLimit;
          },
        );
        setLastBtn(null);
        return;
      } else {
        gsapModuleInstance.otherButtonEffect(lastBtn);
      }
    }
    gsapModuleInstance.extraClickEffect(clickedBtn);
    setLastBtn(e.currentTarget);
  }
  const onHint = () => {
    if (lintingRef.current) {
      return;
    }
    lintingRef.current = true;
    if (hint) {
      updateReceipt(hint, priceFactor * (hintsCount + 1));
      gsapModuleInstance.onHint(hintBtnRef, hint);
      setTimeout(() => {
        gsapModuleInstance.hidePrice();
        setHintsCount((prev) => prev + 1);
      }, 2000);
    }
  };
  const onComplete = async (result: boolean, url: string) => {
    setClientSecret(undefined);
    if (result && url) {
      const report = reportModuleInstance.get();
      const pdfUrl = await generatePDF(
        url,
        report.matches,
        hintsCount,
        report.fastest,
        report.average,
      );
      reportModuleInstance.reset();
      resetReceipt();
      onReplay();
      window.open(pdfUrl, '_blank');
      await paymentSuccess();
    }
  };
  const fetchNewClientSecret = useCallback(async () => {
    const isConfirmed = await reportAlert(recipient.total);
    if (!isConfirmed) return;
    setIsLoading(true);
    const newOrderId = generateRandomString(20);
    setOrderId(newOrderId);
    dispatch(createPaymentAction({ orderId: newOrderId, amount: recipient.total }))
      .unwrap()
      .then((data) => {
        setClientSecret(data.data);
        setIsLoading(false);
      });
  }, [dispatch, recipient.total]);

  return (
    <>
      <div id="container" className="game-container" ref={containerRef} style={{ opacity: 0 }}>
        <div className="game-header">
          <div className="found-label">
            {foundRef.current ? `Found: ${foundRef.current}` : `Find the matching pair`}
          </div>
          <div className="timeTxt">30:00</div>
          <div className="timePlus">+30 sec</div>
        </div>
        <div ref={btnAreaRef} className="emoji-grid">
          {Array.from({ length: 16 }, (_, i) => (
            <div key={i + 1} className="btn" id={`b${i + 1}`} onClick={btnClick}></div>
          ))}
        </div>
        <div
          className="payment-label"
          style={recipient.total <= 5 ? { display: 'none' } : {}}
          onClick={fetchNewClientSecret}
        >{`Performance Analysis (${recipient.total}$)`}</div>

        <div className="hint">
          <span id="price" className="price">{`${(hintsCount + 1) * priceFactor}$`}</span>
          <div id="hintBtn" className="hintBtn hint-text" ref={hintBtnRef} onClick={onHint}>
            Hint
          </div>
        </div>
        <div className="end" style={{ opacity: 0, display: 'none' }}>
          <p className="end-found">Found: {foundRef.current}</p>
          <div className="endTxt"></div>
          <div id="replayBtn" ref={replayBtnRef} onClick={onReplay}>
            <svg xmlns="http://www.w3.org/2000/svg" width="240" height="240" viewBox="0 0 240 240">
              <rect width="240" height="240" fill="none" />
              <path
                fill="#000"
                d="M120,50V10L70,60l50,50V70c33.11,0,60,26.9,60,60c0,33.11-26.89,60-60,60c-33.1,0-60-26.89-60-60H40 c0,44.2,35.8,80,80,80s80-35.8,80-80S164.2,50,120,50z"
              />
            </svg>
          </div>
        </div>
      </div>
      <LoadingSpinner isLoading={isLoading} />
      <GenericModal
        isOpen={!!clientSecret}
        onClose={() => {
          setClientSecret(undefined);
        }}
      >
        {!!clientSecret && (
          <WrappedCheckoutForm
            onComplete={onComplete}
            clientSecret={clientSecret}
            orderId={orderId}
          />
        )}
      </GenericModal>
    </>
  );
};

export default EmojiGame;

import { useCallback, useEffect, useRef, useState } from 'react';
import Phaser from 'phaser';
import '../../App.css';
import GameScene from 'src/pages/dressUp/GameScene';
import { Receipt } from 'src/components/Receipt';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { toggleReceipt } from 'src/utils/purchaseUtils';
import WrappedCheckoutForm from 'src/components/payment/CheckoutForm';
import GenericModal from 'src/components/payment/GenericModal';
import { createPaymentAction } from 'src/store/slices/purchase.slice';
import { generateRandomString } from 'src/utils/utils';
import ControlsManager from 'src/pages/dressUp/ControlsManager';
import { OutputImage } from 'src/components/OutputImage';
import LoadingSpinner from 'src/components/loading/LoadingSpinner';
import { confirmImageSaved, showAlert } from 'src/utils/notificationController';

function DressUp() {
  const game = useRef<Phaser.Game | null>(null);
  const gameContainer = useRef<HTMLDivElement>(null);
  const { visible, recipient } = useAppSelector((state) => state.purchase);
  const dispatch = useAppDispatch();
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [orderId, setOrderId] = useState<string>('');
  const [imageContent, setImageContent] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const registerActions = () => {
    const controlsManager = game.current?.registry.get('controlsManager') as ControlsManager;
    controlsManager?.getCameraButton()?.on('pointerdown', async () => {
      controlsManager?.cameraSound?.play();
      await fetchNewClientSecret();
    });
  };

  useEffect(() => {
    const config: Phaser.Types.Core.GameConfig = {
      type: Phaser.AUTO,
      parent: gameContainer.current || undefined,
      render: {
        preserveDrawingBuffer: true,
      },
      scale: {
        mode: Phaser.Scale.ScaleModes.FIT,
        width: 768,
        height: 1024,
      },
      scene: [GameScene],
    };
    game.current = new Phaser.Game(config);
    setTimeout(() => {
      registerActions();
    }, 1000);
    return () => {
      game.current?.destroy(true);
      toggleReceipt(false);
    };
  }, []);

  useEffect(() => {
    game.current?.scene.getScenes()?.forEach((scene) => {
      if (scene instanceof GameScene) {
        scene.toggleEnabled(!isLoading);
      }
    });
  }, [isLoading]);

  const fetchNewClientSecret = useCallback(async () => {
    const isConfirmed = await showAlert();
    if (!isConfirmed) return;
    setIsLoading(true);
    const newOrderId = generateRandomString(20);
    setOrderId(newOrderId);
    dispatch(createPaymentAction({ orderId: newOrderId, amount: recipient.total }))
      .unwrap()
      .then((data) => {
        toggleReceipt(false);
        setClientSecret(data.data);
        setIsLoading(false);
      });
  }, [dispatch, recipient.total]);

  useEffect(() => {
    registerActions();
  }, [recipient.total]);
  const onComplete = (result: boolean, data: any) => {
    toggleReceipt(true);
    setClientSecret(undefined);
    if (result && data) {
      const controlsManager = game.current?.registry.get('controlsManager') as ControlsManager;
      controlsManager?.doTakeScreenshot(JSON.stringify(data), (imageData) => {
        setImageContent(imageData);
      });
    }
  };
  const closeImage = async () => {
    const isConfirmed = await confirmImageSaved();
    if (isConfirmed) {
      const controlsManager = game.current?.registry.get('controlsManager') as ControlsManager;
      controlsManager?.resetScene();
      setImageContent('');
    }
  };
  return (
    <div>
      <LoadingSpinner isLoading={isLoading} />
      <div ref={gameContainer} className="game-canvas">
        {visible && !clientSecret && !imageContent.length && <Receipt />}
      </div>
      <GenericModal
        isOpen={!!clientSecret}
        onClose={() => {
          setClientSecret(undefined);
          toggleReceipt(true);
        }}
      >
        {!!clientSecret && (
          <WrappedCheckoutForm
            onComplete={onComplete}
            clientSecret={clientSecret}
            orderId={orderId}
          />
        )}
      </GenericModal>
      <GenericModal isOpen={!!imageContent.length} onClose={closeImage}>
        <OutputImage image={imageContent} />
      </GenericModal>
    </div>
  );
}

export default DressUp;

import { loadState } from './storage';
import { rehydrateState } from './actions';

const rehydrateMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type === 'APP_INIT') {
    const savedState = loadState();
    if (savedState) {
      store.dispatch(rehydrateState(savedState));
    }
  }
  return next(action);
};

export default rehydrateMiddleware;

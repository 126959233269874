import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createOrderApi,
  createPaymentIntentApi,
  updateOrderApi,
  updateOrderStateApi,
} from 'src/store/api/purchase.api';
import { RootState } from 'src/store';

export interface purchaseState {
  visible: boolean;
  payment: any | null;
  recipient: any;
  exchangeRate: any | null;
  order: any;
  loading: boolean;
}

export const initialState: purchaseState = {
  visible: false,
  payment: null,
  recipient: { items: [], total: 0 },
  order: {},
  exchangeRate: null,
  loading: false,
};

export const createPaymentAction = createAsyncThunk(
  'payment/create',
  async (
    data: {
      orderId: string;
      amount: number;
    },
    { rejectWithValue },
  ) => {
    try {
      const createOrderResponse = await createPaymentIntentApi(data);
      console.log(`create payment response = ${JSON.stringify(createOrderResponse)}`);
      return createOrderResponse;
    } catch (err: any) {
      console.log(`createOrderAction error = ${JSON.stringify(err)}`);
      return rejectWithValue('Failed to createOrderAction');
    }
  },
);

export const createOrderAction = createAsyncThunk(
  'order/create',
  async (data: any, { rejectWithValue }) => {
    try {
      const createOrderResponse = await createOrderApi(data);
      console.log(`createOrderAction error = ${JSON.stringify(createOrderResponse)}`);
      return createOrderResponse;
    } catch (err: any) {
      console.log(`createOrderAction error = ${JSON.stringify(err)}`);
      return rejectWithValue('Failed to createOrderAction');
    }
  },
);

export const updateOrderAction = createAsyncThunk(
  'order/update',
  async (data: any, { rejectWithValue }) => {
    try {
      const updateOrderResponse = await updateOrderApi(data);
      console.log(`confirmOrderResponse error = ${JSON.stringify(updateOrderResponse)}`);
      return updateOrderResponse;
    } catch (err: any) {
      console.log(`updateOrderAction  error = ${JSON.stringify(err)}`);
      return rejectWithValue('Failed to updateOrderAction');
    }
  },
);

export const updateOrderStateAction = createAsyncThunk(
  'order/update_state',
  async (
    data: {
      orderId: string;
      state: string;
      previousState: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const updateOrderResponse = await updateOrderStateApi(data);
      console.log(`updateOrderStateAction response = ${JSON.stringify(updateOrderResponse)}`);
      return updateOrderResponse;
    } catch (err: any) {
      console.log(`updateOrderStateAction error = ${JSON.stringify(err)}`);
      return rejectWithValue('Failed to updateOrderStateAction');
    }
  },
);

export const showReceiptAction = createAsyncThunk('order/showReceipt', async (data: any) => {
  return data;
});

type ReceiptType = {
  name: string;
  price: number;
  quantity: number;
};
export const updateReceiptAction = createAsyncThunk(
  'order/updateReceipt',
  async (data: ReceiptType, { getState }) => {
    const state = getState() as RootState;
    const purchasedItems = [...(state.purchase.recipient?.items || [])];
    let total = state.purchase.recipient?.total || 0;
    const existingItemIndex = purchasedItems.findIndex((item) => item.name === data.name);
    if (existingItemIndex > -1) {
      const quantity = purchasedItems[existingItemIndex].quantity;
      purchasedItems.splice(existingItemIndex, 1);
      const items = [
        ...purchasedItems,
        { name: data.name, price: data.price, quantity: quantity + 1 },
      ];
      total += data.price;
      return {
        items: items,
        total,
      };
    } else {
      const items = [...purchasedItems, { name: data.name, price: data.price, quantity: 1 }];
      total += data.price;
      return {
        items,
        total,
      };
    }
  },
);

export const ResetReceiptAction = createAsyncThunk('order/resetReceipt', async () => {
  return {
    items: [],
    total: 0,
  };
});

export const paymentSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(showReceiptAction.fulfilled, (state, action) => {
      return {
        ...state,
        visible: action.payload,
      };
    });
    builder.addCase(updateReceiptAction.fulfilled, (state, action) => {
      return {
        ...state,
        recipient: action.payload,
      };
    });
    builder.addCase(ResetReceiptAction.fulfilled, (state, action) => {
      return {
        ...state,
        recipient: action.payload,
      };
    });
  },
});

export default paymentSlice.reducer;

import { type FC } from 'react';

export const OutputImage: FC<{ image: string }> = ({ image }) => {
  const imageName = `playJoz-${new Date().valueOf()}.jpg`;
  return (
    <div>
      <img src={image} alt="Image with barcode" style={{ width: '100%', maxWidth: '500px' }} />
      <br />
      <a href={image} download={imageName}>
        <button>Download Image</button>
      </a>
    </div>
  );
};

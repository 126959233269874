import Phaser from 'phaser';
import KineticScrollingRemakePlugin from 'src/pages/dressUp/KineticScrollingRemakePlugin';
import CategoryManager from 'src/pages/dressUp/CategoryManager';
import { toggleReceipt } from 'src/utils/purchaseUtils';

const setUpKineticScrolling = (scene: Phaser.Scene) => {
  if (!scene.plugins.get('KineticScrollingPlugin')) {
    scene.plugins.installScenePlugin(
      'KineticScrollingPlugin',
      KineticScrollingRemakePlugin,
      'kineticScrolling',
      scene,
    );
    (scene as any).kineticScrolling.start();
    (scene as any).kineticScrolling.configure({
      kineticMovement: true,
      timeConstantScroll: 325,
      horizontalScroll: true,
      verticalScroll: false,
      horizontalWheel: true,
      verticalWheel: false,
      deltaWheel: 180,
    });
  }
};

export function startScene(this: Phaser.Scene) {
  setUpKineticScrolling(this);
  this.cameras.main.setBackgroundColor(undefined);
  // Load atlases
  this.load.atlas('dressup', 'assets/dressUp/dressup.png', 'assets/dressUp/dressup.json');
  this.load.atlas(
    'DressuppThumb',
    'assets/dressUp/DressuppThumb.png',
    'assets/dressUp/DressuppThumb.json',
  );

  // Load audio
  this.load.audio('eventAudio', 'assets/dressUp/audio/clickWater.wav');
  this.load.audio('bgAudio', 'assets/dressUp/audio/bgMusic.mp3');
  this.load.audio('camera', 'assets/dressUp/audio/camera.wav');

  // Load images
  this.load.image('backgroundP', 'assets/dressUp/backgroundP.png');
  this.load.image('bgSlider', 'assets/dressUp/bgSlider.png');
  this.load.start();
}

export function tweenPosition(this: Phaser.Scene, target: number) {
  this.tweens.add({
    targets: this.cameras.main,
    scrollX: target,
    duration: 500,
    ease: 'Linear',
  });
  const flag = false;
}

export function loadComplete(this: Phaser.Scene) {
  const kineticScrolling = (this as any).kineticScrolling;

  // Initialize audio
  const bgAudio = this.sound.add('bgAudio', { loop: true, volume: 0.2 });
  bgAudio.play();
  const clickSound = this.sound.add('eventAudio');

  // Background and main elements
  this.add.sprite(0, 0, 'backgroundP').setOrigin(0).setScrollFactor(0);
  this.add
    .sprite(this.scale.width / 2, this.scale.height - 15, 'bgSlider')
    .setOrigin(0.5, 1)
    .setScrollFactor(0);
  const womenGroup = (this as any).women;
  womenGroup.start();
  const categoryGroup = new CategoryManager(this, womenGroup, kineticScrolling, clickSound);
  categoryGroup.start();
  setTimeout(() => {
    toggleReceipt(true);
  }, 2000);
}

import { type FC } from 'react';
import './LoadingSpinner.style.css';

const LoadingSpinner: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div
      className="spinner-overlay"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
      <p>Please wait while preparing the payment...</p>
    </div>
  );
};

export default LoadingSpinner;

import { configureStore } from '@reduxjs/toolkit';
import reducer from 'src/store/reducer';
import { errorLoggingMiddleware } from 'src/store/middlewares/errorLogging.middleware';
import rehydrateMiddleware from 'src/store/persistor/rehydrateMiddleware';
import persistMiddleware from 'src/store/persistor/persistMiddleware';

const appReducer = (state: any, action: any) => {
  if (action.type === 'REHYDRATE_STATE') {
    return { ...state, ...action.payload };
  }
  return reducer(state, action);
};

const index = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(errorLoggingMiddleware, rehydrateMiddleware, persistMiddleware),
});

export type RootState = ReturnType<typeof index.getState>;
export type AppDispatch = typeof index.dispatch;
export const storeDispatcher = (action: any) => index.dispatch(action);
export const storeStateGetter = (state: string) => index.getState()[state];
export default index;

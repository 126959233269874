export class Preloader extends Phaser.Scene
{
  constructor()
  {
    super({
      key: 'Preloader'
    });
  }

  preload ()
  {
    this.load.image("volume-icon", "assets/cardMemory/volume-icon.png");
    this.load.image("volume-icon_off", "assets/cardMemory/volume-icon_off.png");

    this.load.audio("theme-song", "assets/cardMemory/audio/fat-caps-audionatix.mp3");
    this.load.audio("whoosh", "assets/cardMemory/audio/whoosh.mp3");
    this.load.audio("card-flip", "assets/cardMemory/audio/card-flip.mp3");
    this.load.audio("card-match", "assets/cardMemory/audio/card-match.mp3");
    this.load.audio("card-mismatch", "assets/cardMemory/audio/card-mismatch.mp3");
    this.load.audio("card-slide", "assets/cardMemory/audio/card-slide.mp3");
    this.load.audio("victory", "assets/cardMemory/audio/victory.mp3");
    this.load.image("background", "assets/cardMemory/background.png");
    this.load.image("card-back", "assets/cardMemory/card-back.png");
    this.load.image("card-0", "assets/cardMemory/card-0.png");
    this.load.image("card-1", "assets/cardMemory/card-1.png");
    this.load.image("card-2", "assets/cardMemory/card-2.png");
    this.load.image("card-3", "assets/cardMemory/card-3.png");
    this.load.image("card-4", "assets/cardMemory/card-4.png");
    this.load.image("card-5", "assets/cardMemory/card-5.png");

    this.load.image("heart", "assets/cardMemory/heart.png");

  }

  create ()
  {
    this.scene.start("Play");
  }
}

import React from 'react';
import { Link } from 'react-router-dom';
import 'src/style/MaintenancePage.css';

function MaintenancePage() {
  return (
    <div className="maintenance-page">
      <h1>We&#39;re Improving Things!</h1>
      <p>The game you&#39;re trying to access is currently under maintenance.</p>
      <p>We’re working hard to bring it back soon. Stay tuned!</p>
      <Link to="/" className="home-link">
        Go Back to Home
      </Link>
    </div>
  );
}

export default MaintenancePage;

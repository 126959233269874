import Phaser from 'phaser';
import KineticScrollingRemakePlugin from 'src/pages/dressUp/KineticScrollingRemakePlugin';
import { soundType } from 'src/pages/dressUp/types';

export default class ItemManager {
  private women: Phaser.GameObjects.Group;
  private kineticScrolling: KineticScrollingRemakePlugin;
  private sound: soundType;
  scene: Phaser.Scene;
  readonly itemContainer: Phaser.GameObjects.Container;
  public visibleItems = 5;
  private readonly centerX: number;
  private readonly centerY: number;

  constructor(
    scene: Phaser.Scene,
    womenGroup: Phaser.GameObjects.Group,
    kineticScrollingPlugin: KineticScrollingRemakePlugin,
    clickSound: soundType,
  ) {
    this.women = womenGroup;
    this.kineticScrolling = kineticScrollingPlugin;
    this.sound = clickSound;
    this.scene = scene;
    this.itemContainer = this.scene.add.container(0, 0);
    this.centerX = this.scene.scale.width / 2;
    this.centerY = this.scene.scale.height / 2;
  }

  handleItemSelection(itemSprite: Phaser.GameObjects.Sprite) {
    const itemCategory = itemSprite.frame.name.split('/')[0];
    const isGroupItem = this.isPartOf(itemCategory, ['Dresses', 'Top', 'Skirt', 'Trousers']);
    if (this.kineticScrolling.amplitudeX === 0) {
      this.sound.play();
      this.clearCategoryAndGroupItems(itemCategory);
      if (itemCategory === 'Bodies') {
        this.addItem(itemSprite, 0);
      } else if (isGroupItem) {
        this.addItem(itemSprite, 1);
      } else if (itemCategory === 'Hair') {
        this.addItem(itemSprite, 101);
      } else if (itemCategory === 'Eyebrows') {
        this.addItem(itemSprite, 101);
      } else {
        this.addItem(itemSprite, 1);
      }
      this.sortGroupByDepth();
    }
  }

  private clearCategoryAndGroupItems(category: string) {
    const womenChildren = this.women.getChildren() as Phaser.GameObjects.Sprite[];
    // Clear items of the same category and associated group items
    const groupCategories = this.getRelatedCategories(category);
    for (let i = womenChildren.length - 1; i >= 0; i--) {
      const childCategory = womenChildren[i].frame.name.split('/')[0];
      if (childCategory === category || groupCategories.includes(childCategory)) {
        this.women.remove(womenChildren[i], true, true);
      }
    }
  }

  private addItem(itemSprite: Phaser.GameObjects.Sprite, depth: number) {
    const newItem = this.women.scene.add.sprite(
      this.centerX,
      this.centerY,
      'dressup',
      itemSprite.frame.name,
    );
    newItem.setDepth(depth);
    this.women.add(newItem);
  }
  private sortGroupByDepth() {
    this.women.getChildren().sort((a, b) => {
      const spriteA = a as Phaser.GameObjects.Sprite;
      const spriteB = b as Phaser.GameObjects.Sprite;
      return spriteA.depth - spriteB.depth;
    });
  }

  private isPartOf(category: string, categories: string[]): boolean {
    return categories.includes(category);
  }

  private getRelatedCategories(category: string): string[] {
    // Returns all related categories that belong to the same group as the category
    if (category === 'Dresses') return ['Top', 'Skirt', 'Trousers'];
    if (category === 'Top') return ['Dresses'];
    if (category === 'Skirt') return ['Dresses', 'Trousers'];
    if (category === 'Trousers') return ['Dresses', 'Skirt'];
    return [];
  }
  startDragging(item: Phaser.GameObjects.Sprite) {
    this.kineticScrolling.configure({
      kineticMovement: true,
      horizontalScroll: true,
    });
    item.setScale(1.2);
    this.scene.time.delayedCall(200, () => {
      item?.setScale(1);
    });
  }
  renderCategoryItems(itemFolder: string, itemCount: number) {
    const maskShape = this.scene.add.graphics({});
    maskShape.fillRect(this.scene.scale.width / 2 - 322, this.scene.scale.height - 200, 650, 200);
    const mask = maskShape.createGeometryMask();
    this.itemContainer.setMask(mask);

    let itemPositionX = 70;
    for (let i = 1; i <= itemCount; i++) {
      const itemSprite = this.scene.add.sprite(
        itemPositionX,
        this.scene.scale.height - 95,
        'DressuppThumb',
        `${itemFolder}${i}.png`,
      );
      itemSprite.setOrigin(0, 0.5);
      itemSprite.setInteractive({ useHandCursor: true });
      itemSprite.setScale(1);
      this.itemContainer.add(itemSprite);

      itemPositionX += (this.scene.scale.width - 100) / this.visibleItems;

      itemSprite.on('pointerup', () => this.handleItemSelection(itemSprite));
      itemSprite.on('pointerdown', () => this.startDragging(itemSprite));
    }
  }

  clearItems() {
    this.itemContainer.x = 0;
    this.scene.cameras.main.scrollX = 0;
    this.itemContainer.removeAll(true);
  }
}

import React from 'react';
import './PyamentModal.style.css';

type ModalProps = React.PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
}>;

const GenericModal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default GenericModal;

import Phaser from 'phaser';
import Women from 'src/pages/dressUp/Women';
import KineticScrollingRemakePlugin from 'src/pages/dressUp/KineticScrollingRemakePlugin';
import { loadComplete, startScene } from 'src/pages/dressUp/SceneUtils';

export default class GameScene extends Phaser.Scene {
  public women: Women;
  private bmpText: Phaser.GameObjects.Text | undefined;
  public kineticScrolling: KineticScrollingRemakePlugin | undefined;
  constructor() {
    super('game');
    this.women = new Women(this);
    this.bmpText = undefined;
    this.kineticScrolling = undefined;
  }
  toggleEnabled(enabled: boolean) {
    this.input.enabled = enabled;
    console.warn('input enabled', enabled);
  }
  preload() {
    this.scale.scaleMode = Phaser.Scale.ScaleModes.FIT;
    this.scale.autoCenter = Phaser.Scale.CENTER_BOTH;
    this.load.image('loadScreen', 'assets/dressUp/Loading.png');
    this.load.bitmapFont(
      'dressFont',
      'assets/dressUp/font/dress-font.png',
      'assets/dressUp/font/dress-font.xml',
    );
  }

  loadStart() {
    if (this.bmpText) this.bmpText.setText('Loading ...');
  }

  updateProgress(progress: number) {
    if (this.bmpText) {
      const percentage = Math.floor(progress * 100);
      this.bmpText.setText(`Loading... ${percentage}%`);
    }
  }
  create() {
    const loadScreen = this.add.sprite(0, 0, 'loadScreen');
    loadScreen.setOrigin(0, 0);
    this.bmpText = this.add
      .text(this.scale.width / 2 - 60, this.scale.height / 2 + 150, 'Loading...', {
        font: '26px Arial',
        color: '#100f0f',
      })
      .setOrigin(0.5);
    this.load.on('start', this.loadStart, this);
    this.load.on('progress', this.updateProgress, this);
    this.load.on('complete', loadComplete.bind(this), this);
    setTimeout(() => {
      startScene.call(this);
    }, 100);
  }
  update() {}
}

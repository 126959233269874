export const parseToJson = (params: any) => {
  try {
    if (typeof params === 'string') {
      return JSON.parse(params);
    }
    return params;
  } catch {
    return {};
  }
};

export const parseToArray = (params: string | undefined) => {
  if (Array.isArray(params)) return params;
  try {
    return JSON.parse(params || '[]');
  } catch {
    return [];
  }
};

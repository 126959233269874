import { gsap } from 'gsap';
import { RefObject } from 'react';

export default class GsapModule {
  private emojis = [
    '😄',
    '🤣',
    '🙂',
    '🙃',
    '😉',
    '😇',
    '😍',
    '🤥',
    '😘',
    '😚',
    '😛',
    '😜',
    '😋',
    '🤗',
    '🤔',
    '🤐',
    '😶',
    '🤑',
    '😏',
    '🙄',
    '😳',
    '😬',
    '😴',
    '🤕',
    '🤠',
    '🤧',
    '😢',
    '😵',
    '😎',
    '🤓',
    '😡',
    '🤢',
    '😭',
    '😫',
    '😠',
  ];
  private btns: string[] = [];
  static instance: GsapModule | null = null;
  private isHinting = false;
  private constructor() {
    console.warn('GsapModule constructor');
  }

  public static getInstance(): GsapModule {
    if (!this.instance) {
      this.instance = new GsapModule();
    }
    return this.instance;
  }
  shuffleArray(array: string[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  rand(min: number, max: number) {
    min = min || 0;
    max = max || 1;
    return min + (max - min) * Math.random();
  }
  makeNewNum(all: string[]) {
    if (all.length >= this.emojis.length) {
      return null;
    }
    let n;
    do {
      n = this.emojis[Math.floor(this.rand(0, this.emojis.length))];
    } while (all.includes(n));
    return n;
  }

  populate(
    btnAreaRef: RefObject<HTMLDivElement>,
    hintBtnRef: RefObject<HTMLDivElement>,
    hintCallBack: (hint: string) => void,
  ) {
    this.btns = [];
    gsap.set(hintBtnRef.current, { autoAlpha: 0, textContent: 'Hint?', fontSize: 25 });
    gsap.set('.found-label', { display: 'block' });
    gsap.fromTo(
      '.btn',
      { scale: 0.2, opacity: 0, rotation: 1 },
      {
        rotation: 0,
        opacity: 1,
        scale: 1,
        ease: 'back.out(4)',
        stagger: { grid: [4, 4], from: 'center', amount: 0.2 },
        onComplete: () => {
          // console.log("Buttons animation completed");
        },
      },
    );
    for (let i = 0; i < 15; i++) {
      const newEmoji = this.makeNewNum(this.btns);
      if (newEmoji) this.btns.push(newEmoji);
    }
    hintCallBack(this.btns[14]);
    this.btns = this.shuffleArray([...this.btns, this.btns[14]]);
    const btnsDivs = btnAreaRef.current?.querySelectorAll('.btn') || [];
    btnsDivs.forEach((btnDiv: any, index: number) => {
      btnDiv.textContent = this.btns[index];
    });
  }

  startGame(
    replayBtnRef: RefObject<HTMLDivElement>,
    btnAreaRef: RefObject<HTMLDivElement>,
    hintBtnRef: RefObject<HTMLDivElement>,
    callBack: (hint: string) => void,
  ) {
    const populate = this.populate.bind(this, btnAreaRef, hintBtnRef, callBack);
    this.isHinting = false;
    gsap
      .timeline({ onStart: populate })
      // .set('#container', { userSelect: 'none', background: 'radial-gradient(#333, #000 120%)' })
      .set('#container', { userSelect: 'none' })
      .set('.btn', {
        width: 90,
        height: 90,
        borderRadius: '50%',
        border: '3px solid transparent',
        textAlign: 'center',
        fontSize: 64,
        lineHeight: '86px',
        cursor: 'pointer',
      })
      .set(hintBtnRef.current, {
        right: 30,
        bottom: 30,
        width: 75,
        fontSize: 25,
        textAlign: 'center',
        cursor: 'pointer',
        autoAlpha: 0,
      })
      .set('.timeTxt', { left: 0, top: 20, fontSize: 22 })
      .set('.timePlus', { left: 28, top: 75, fontSize: 21, opacity: 0 })
      .set(replayBtnRef.current, {
        left: 400,
        top: 275,
        scale: 0.6,
        transformOrigin: '120px 130px',
        cursor: 'pointer',
      })
      .to('#container', { duration: 0.2, opacity: 1, ease: 'power2.in' }, 0);
  }
  onReplay(
    replayBtnRef: RefObject<HTMLDivElement>,
    btnAreaRef: RefObject<HTMLDivElement>,
    hintBtnRef: RefObject<HTMLDivElement>,
    callBack: () => void,
    callBackHint: (hint: string) => void,
  ) {
    this.isHinting = false;
    const populate = this.populate.bind(this, btnAreaRef, hintBtnRef, callBackHint);
    gsap
      .timeline()
      .to(replayBtnRef.current, { rotation: -180, scale: 0, ease: 'back.in(3)', duration: 0.5 }, 0)
      .to('.end', { autoAlpha: 0, duration: 0.3 }, 0.5)
      .to(['.timeTxt', btnAreaRef.current], { autoAlpha: 1, duration: 0.3 }, 0.8)
      .call(
        function () {
          gsap.set('.timeTxt', { textContent: '30:00' });
          callBack();
          populate();
        },
        [],
        undefined,
      );
  }
  afterMatching(
    btnAreaRef: RefObject<HTMLDivElement>,
    hintBtnRef: RefObject<HTMLDivElement>,
    found: number,
    lastBtn: HTMLElement,
    currentTarget: HTMLElement,
    callBack: (hint: string) => void,
  ) {
    this.isHinting = false;
    const populate = this.populate.bind(this, btnAreaRef, hintBtnRef, callBack);

    gsap
      .timeline({ onComplete: populate })
      // Correct use of opacity instead of alpha
      .to('.timePlus', { opacity: 1, yoyo: true, repeat: 1, repeatDelay: 0.4, duration: 0.1 }, 0)
      // Use fromTo with correct property names
      .fromTo(
        '.timePlus',
        { scale: 0, rotation: 0.1 }, // From values
        { scale: 1, rotation: 0, duration: 0.3 }, // To values
        0,
      )
      .to([currentTarget, lastBtn], { border: '3px solid #08c04d', duration: 0.1 }, 0)
      // Apply correct easing with Back.easeIn
      .to(
        currentTarget,
        { rotation: 1, scale: 0.8, ease: 'back.in(7)', yoyo: true, repeat: 1, duration: 0.3 },
        0,
      )
      .to(
        lastBtn,
        { rotation: 1, scale: 0.8, ease: 'back.in(7)', yoyo: true, repeat: 1, duration: 0.3 },
        0,
      )
      .to('.btn', { border: '3px solid transparent', duration: 0.1 }, 0.5); //reset borders set in extraClickEffect
  }
  onHint(hintBtnRef: RefObject<HTMLDivElement>, hint: string | null) {
    this.isHinting = true;
    gsap.set(hintBtnRef.current, { textContent: hint || '', fontSize: 35 });
    gsap.fromTo(
      '.price',
      {
        x: 0,
        y: 0,
        scale: 1,
        opacity: 1,
      },
      {
        x: 0,
        y: -200,
        scale: 0.5,
        opacity: 0,
        duration: 2,
        ease: 'power1.inOut',
        onComplete: () => {
          gsap.set('.price', {
            x: 0,
            y: 0,
            scale: 1,
            opacity: 0,
          });
        },
      },
    );
    gsap.to('.payment-label', {
      x: -10,
      y: 5,
      duration: 0.1,
      yoyo: true,
      repeat: 5,
      onComplete: () => {
        gsap.to('.payment-label', { x: 0, y: 0 });
      },
    });
  }
  hidePrice() {
    gsap.set('.price', { opacity: 0 });
  }
  showHint() {
    gsap.set('.hint', { autoAlpha: 1 });
  }
  hideHint() {
    gsap.set('.hint', { autoAlpha: 0 });
  }
  updateTime(
    btnAreaRef: RefObject<HTMLDivElement>,
    hintBtnRef: RefObject<HTMLDivElement>,
    timeLeft: number,
    found: number,
    hint: string | null,
    replayBtnRef: RefObject<HTMLDivElement>,
    msTilHint: number,
    updateLeftTime: () => void,
    updateHintTime: () => void,
    onGameOver: () => void,
  ) {
    if (timeLeft > 0) {
      updateLeftTime();
      const mil = Math.floor((timeLeft % 1000) / 10);
      const sec = Math.floor(timeLeft / 1000);
      const milStr = mil < 10 ? '0' + mil : mil;
      const secStr = sec < 10 ? '0' + sec : sec;
      const t = `${secStr}:${milStr}`;
      gsap.set('.timeTxt', { textContent: t });
      if (msTilHint < 1) {
        this.showHint();
        gsap.to(hintBtnRef.current, { duration: 0.5, autoAlpha: 1 });
        if (!this.isHinting) {
          gsap.to('.price', { duration: 0.5, opacity: 1 });
        }
      } else {
        updateHintTime();
      }
    } else {
      onGameOver();
      gsap.set('.found-label', { display: 'none' });
      gsap.set('.endTxt', { textContent: hint + ' Try again? ' + hint, fontSize: 32 });
      gsap
        .timeline()
        .to('.timeTxt', { autoAlpha: 0, duration: 0.3 }, 0)
        .to(btnAreaRef.current, { autoAlpha: 0, duration: 0.3 }, 0)
        .to('.btn', { border: '3px solid transparent', duration: 0.1 }, 0.3)
        .set('.end', { display: 'block', ease: 'power2.inOut', duration: 0.6 }, 0)
        .to('.end', { autoAlpha: 1, ease: 'power2.inOut', duration: 0.6 }, 0)
        .fromTo(
          replayBtnRef.current,
          { rotation: 360, scale: 0 },
          { scale: 0.6, rotation: 0, ease: 'power1.inOut', duration: 1.0 },
          0.5,
        );
    }
  }

  clickEffect(clickedItem: HTMLElement) {
    gsap.to(clickedItem, { duration: 0.05, scale: 0.95, yoyo: true, repeat: 1 });
  }

  otherButtonEffect(clickedItem: HTMLElement) {
    gsap.to(clickedItem, { duration: 0.1, border: '3px solid transparent' });
  }

  extraClickEffect(clickedItem: HTMLElement) {
    gsap.to(clickedItem, { duration: 0.1, border: '3px solid #006da6' });
  }
}

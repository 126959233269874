import React from 'react';
import '../App.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <a
          href="https://playjoz.com/terms_and_conditions.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>
        <a href="https://playjoz.com/privacy_policy.html" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </div>
      <p className="footer-copyright">
        &copy; {new Date().getFullYear()} PlayJoz. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;

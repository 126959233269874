import Phaser from 'phaser';

export default class Women extends Phaser.GameObjects.Group {
  dressSprites = ['Bodies/1.png', 'Hair/1.png', 'Lips/1.png', 'Eyes/1.png', 'Eyebrows/12.png'];
  scene: Phaser.Scene;
  public constructor(scene: Phaser.Scene) {
    super(scene);
    this.scene = scene;
  }
  public start() {
    this.dressSprites.forEach((spriteFrame, index) => {
      const sprite = this.scene.add.sprite(0, 0, 'dressup', spriteFrame).setScrollFactor(0);
      sprite.setDepth(index + 1);
      this.add(sprite);
    });
    this.setXY(0, 0);
    this.setOrigin(0, 0);
  }
}

import Swal from 'sweetalert2';
import { storeStateGetter } from 'src/store';

export const showAlert = async () => {
  const receipt = storeStateGetter('purchase').recipient;
  let result = { isConfirmed: false };
  if (receipt.total === 0) {
    await Swal.fire({
      title: `No Naked Pictures!`,
      text: 'Please buy me some clothes and accessories first!',
      icon: 'warning',
      confirmButtonText: 'Got it!',
      customClass: {
        popup: 'swal-custom-popup',
      },
    });
  } else {
    result = await Swal.fire({
      title: `Payment Required`,
      text: 'Please pay your receipt before taking a picture!',
      icon: 'warning',
      confirmButtonText: 'Got it!',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'swal-custom-popup',
      },
    });
  }
  return result.isConfirmed;
};

export async function confirmImageSaved() {
  const result = await Swal.fire({
    title: 'Did you save the image?',
    text: "Please confirm you've saved the image before closing.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, I saved it!',
    cancelButtonText: 'Cancel',
    customClass: {
      popup: 'swal-custom-popup',
    },
  });
  return result.isConfirmed;
}

export const showError = (error: string) => {
  Swal.fire({
    title: 'Payment Failed',
    text: error,
    icon: 'error',
    confirmButtonText: 'Ok',
    customClass: {
      popup: 'swal-custom-popup',
    },
  });
};

export const reportAlert = async (amount: number) => {
  const result = await Swal.fire({
    title: `Payment Required`,
    text: `To access your performance report, please pay your receipt of ${amount}$ first.`,
    icon: 'warning',
    confirmButtonText: 'Got it!',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    customClass: {
      popup: 'swal-custom-popup',
    },
  });
  return result.isConfirmed;
};

export const paymentSuccess = async () => {
  await Swal.fire({
    icon: 'success',
    title: 'Payment Successful!',
    text: 'Thank you for your payment. Your transaction was completed successfully.',
    confirmButtonText: 'OK',
    timer: 5000,
    timerProgressBar: true,
  });
};

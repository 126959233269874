import { useState, useEffect, useCallback } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks/reduxHooks';
import { updateOrderStateAction } from 'src/store/slices/purchase.slice';
import { resetReceipt } from 'src/utils/purchaseUtils';
import { showError } from 'src/utils/notificationController';
import { getEnv } from 'src/utils/envUtils';

interface UseStripeRedirectProps {
  onComplete: (success: boolean, data: any) => void;
}

const useStripeRedirect = ({ onComplete }: UseStripeRedirectProps) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const dispatch = useAppDispatch();

  // Function to process the payment result
  const processPaymentResult = useCallback(
    async (clientSecret: string, orderId: string) => {
      const result = await stripe?.retrievePaymentIntent(clientSecret);
      if (result?.paymentIntent) {
        const { status } = result.paymentIntent;
        const data = `https://modx-army.net/order?order_id=${orderId}`;

        if (status === 'succeeded') {
          dispatch(updateOrderStateAction({ orderId, state: 'paid', previousState: 'pending' }));
          resetReceipt();
          onComplete(true, data);
        } else {
          dispatch(updateOrderStateAction({ orderId, state: 'failed', previousState: 'pending' }));
          showError('Payment failed');
          onComplete(false, null);
        }
      }
    },
    [dispatch, onComplete, stripe],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const clientSecret = searchParams.get('payment_intent_client_secret');
    const orderId = searchParams.get('order_id');

    if (clientSecret && orderId) {
      processPaymentResult(clientSecret, orderId).then(() => 1);
    }
  }, [location.search, processPaymentResult]);

  const confirmPayment = async (orderId: string) => {
    if (!stripe || !elements) return;

    setLoading(true);

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${getEnv('APP_URL')}?order_id=${orderId}`,
      },
      redirect: 'if_required',
    });

    if (result.error) {
      showError(result.error.message || '');
      dispatch(updateOrderStateAction({ orderId, state: 'failed', previousState: 'pending' }));
      onComplete(false, null);
    } else if (result.paymentIntent?.status === 'succeeded') {
      const clientSecret = result.paymentIntent.client_secret;
      if (clientSecret) {
        await processPaymentResult(clientSecret, orderId);
      }
    }
    setLoading(false);
  };
  return { confirmPayment, loading };
};

export default useStripeRedirect;

import {
  ResetReceiptAction,
  showReceiptAction,
  updateReceiptAction,
} from 'src/store/slices/purchase.slice';
import { storeDispatcher } from 'src/store';

export const updateReceipt = (item: string, price: number) => {
  storeDispatcher(updateReceiptAction({ name: item, price, quantity: 1 }));
};

export const toggleReceipt = (visible: boolean) => {
  storeDispatcher(showReceiptAction(visible));
};

export const resetReceipt = () => {
  storeDispatcher(ResetReceiptAction());
};

import { saveState } from './storage';

const persistMiddleware = (store: any) => (next: any) => (action: any) => {
  const result = next(action);
  const whiteList = [
    'auth',
    'userProfile',
    'categories',
    'offers',
    'dishes',
    'restaurants',
    'notifications',
    'reviews',
  ];
  const stateToSave = store.getState();
  if ('auth' in stateToSave && !!stateToSave.auth) {
    saveState(stateToSave, whiteList);
  }
  return result;
};

export default persistMiddleware;

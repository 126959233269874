import { type FC } from 'react';
import { useStripe, PaymentElement } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptionsClientSecret } from '@stripe/stripe-js';
import { getEnv } from 'src/utils/envUtils';
import BaseButton from 'src/components/BaseButton';
import { useAppSelector } from 'src/hooks/reduxHooks';
import useStripeRedirect from 'src/hooks/useStripeRedirect';

const stripePromise = loadStripe(getEnv('STRIPE_PUBLIC_KEY'));

interface CheckoutFormProps {
  onComplete: (success: boolean, data: any) => void;
  orderId: string;
}

const CheckoutForm: FC<CheckoutFormProps> = ({ orderId, onComplete }) => {
  const { recipient } = useAppSelector((state) => state.purchase);
  const stripe = useStripe();
  const { confirmPayment, loading } = useStripeRedirect({ onComplete });
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    await confirmPayment(orderId);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div style={{ marginTop: 20 }}>
        <BaseButton $loading={loading} disabled={!stripe || loading} $rounded>
          {`Confirm Payment ${recipient.total}$`}
        </BaseButton>
      </div>
    </form>
  );
};

interface WrappedCheckoutFormProps {
  onComplete: (success: boolean, data: any) => void;
  clientSecret?: string;
  orderId: string;
}
const WrappedCheckoutForm: FC<WrappedCheckoutFormProps> = ({
  orderId,
  onComplete,
  clientSecret,
}) => {
  const options: StripeElementsOptionsClientSecret = {
    locale: 'en',
    clientSecret,
    appearance: {
      variables: {
        colorText: '#000000',
      },
    },
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm onComplete={onComplete} orderId={orderId} />
    </Elements>
  );
};
export default WrappedCheckoutForm;

import React from 'react';

interface BaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $loading?: boolean;
  $rounded?: boolean;
  children: React.ReactNode;
}

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    position: 'relative',
    marginBottom: '0.5rem',
    display: 'flex',
    height: '3rem',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: 1,
    letterSpacing: '0.1em',
    cursor: 'pointer',
    backgroundColor: '#6B7280', // default background
    borderRadius: '1.5rem', // default rounded style
  },
  rounded: {
    borderRadius: '3rem',
  },
  disabled: {
    cursor: 'not-allowed',
    backgroundColor: '#a1a1a1', // disabled background color
  },
  before: {
    content: "''",
    position: 'absolute',
    right: '-1.25rem',
    top: '-0.75rem',
    zIndex: 10,
    height: '2rem',
    width: '3rem',
    transform: 'rotate(45deg)',
    backgroundColor: '#d1d5db',
  },
  after: {
    content: "''",
    position: 'absolute',
    right: '-1rem',
    top: '-1rem',
    height: '3rem',
    width: '3rem',
    transform: 'rotate(45deg)',
    borderRadius: '50%',
    backgroundColor: 'rgba(229, 231, 235, 0.3)',
  },
};
const BaseButton: React.FC<BaseButtonProps> = ({
  $loading,
  $rounded,
  children,
  disabled,
  ...props
}) => {
  return (
    <button
      style={{
        ...styles.container,
        ...(disabled ? styles.disabled : {}),
        ...($rounded ? styles.rounded : {}),
      }}
      disabled={disabled || $loading}
      {...props}
    >
      {$loading ? 'Loading...' : children}
      <span style={styles.before}></span>
      <span style={styles.after}></span>
    </button>
  );
};

export default BaseButton;

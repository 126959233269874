export default class ReportModule {
  static instance: ReportModule | null = null;
  private matches: number[] = [];
  private gamesPlayed: number = 0;
  private constructor() {
    console.warn('ReportModule constructor');
  }

  public static getInstance(): ReportModule {
    if (!this.instance) {
      this.instance = new ReportModule();
    }
    return this.instance;
  }

  get() {
    const fastest = this.getMinimumDifference(this.matches);
    const average = this.getAverageDifference(this.matches);
    return {
      matches: this.matches.length,
      fastest: Math.round(fastest / 1000),
      average: Math.round(average / 1000),
    };
  }
  setLastMatch(match: number) {
    this.matches.push(match);
  }
  playedGame() {
    this.gamesPlayed++;
  }
  getMinimumDifference(numbers: number[]) {
    if (numbers.length < 2) {
      return 0;
    }
    numbers.sort((a, b) => a - b);
    let minDifference = Infinity;
    for (let i = 1; i < numbers.length; i++) {
      const difference = numbers[i] - numbers[i - 1];
      minDifference = Math.min(minDifference, difference);
    }
    return minDifference;
  }
  getAverageDifference(numbers: number[]) {
    if (numbers.length < 2) {
      return 0;
    }
    numbers.sort((a, b) => a - b);
    let sumOfDifferences = 0;
    for (let i = 1; i < numbers.length; i++) {
      sumOfDifferences += numbers[i] - numbers[i - 1];
    }
    return sumOfDifferences / (numbers.length - 1);
  }
  reset() {
    this.matches = [];
    this.gamesPlayed = 0;
  }
}

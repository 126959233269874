import { fetchService } from 'src/services/fetchService';

export const createPaymentIntentApi = async (data: {
  orderId: string;
  amount: number;
}): Promise<any> => {
  try {
    const rec = await fetchService.post<any>(`create_payment`, { data });
    console.log('createOrderApi ', JSON.stringify(rec));
    return rec;
  } catch (e: any) {
    console.error('createOrderApi ', JSON.stringify(e.message()));
    throw new Error(`createOrderApi Error: ${e}`);
  }
};

export const createOrderApi = async (data: any): Promise<any> => {
  try {
    const rec = await fetchService.post<any>(`create_order`, { data });
    console.log('createOrderApi ', JSON.stringify(rec));
    return rec;
  } catch (e: any) {
    console.error('createOrderApi ', JSON.stringify(e.message()));
    throw new Error(`createOrderApi Error: ${e}`);
  }
};

export const updateOrderApi = async (data: any): Promise<any> => {
  try {
    const rec = await fetchService.post<any>(`update_order`, { data });
    console.log('updateOrderApi ', JSON.stringify(rec));
    return rec;
  } catch (e: any) {
    console.error('updateOrderApi ', JSON.stringify(e.message()));
    throw new Error(`updateOrderApi Error: ${e}`);
  }
};

export const updateOrderStateApi = async (data: {
  orderId: string;
  state: string;
  previousState: string;
}): Promise<any> => {
  try {
    const rec = await fetchService.post<any>(`update_order_status`, { data });
    console.log('updateOrderStateApi ', JSON.stringify(rec));
    return rec;
  } catch (e: any) {
    console.error('updateOrderStateApi error ', JSON.stringify(e.message()));
    throw new Error(`updateOrderStateApi Error: ${e}`);
  }
};

import Phaser from 'phaser';

export default class KineticScrollingRemakePlugin extends Phaser.Plugins.BasePlugin {
  dragging: boolean;
  timestamp: number;
  targetX: number;
  targetY: number;
  autoScrollX: boolean;
  autoScrollY: boolean;
  startX: number;
  startY: number;
  velocityX: number;
  velocityY: number;
  amplitudeX: number;
  amplitudeY: number;
  sceneCamera: Phaser.Cameras.Scene2D.Camera | null;
  settings: {
    kineticMovement: boolean;
    timeConstantScroll: number;
    horizontalScroll: boolean;
    verticalScroll: boolean;
    horizontalWheel: boolean;
    verticalWheel: boolean;
    deltaWheel: number;
  };

  constructor(pluginManager: Phaser.Plugins.PluginManager) {
    super(pluginManager);
    this.dragging = false;
    this.timestamp = 0;
    this.targetX = 0;
    this.targetY = 0;
    this.autoScrollX = false;
    this.autoScrollY = false;
    this.startX = 0;
    this.startY = 0;
    this.velocityX = 0;
    this.velocityY = 0;
    this.amplitudeX = 0;
    this.amplitudeY = 0;
    this.sceneCamera = null;

    this.settings = {
      kineticMovement: true,
      timeConstantScroll: 325,
      horizontalScroll: true,
      verticalScroll: true,
      horizontalWheel: true,
      verticalWheel: true,
      deltaWheel: 40,
    };
  }
  boot() {
    // This can remain empty or contain initialization logic
  }
  setCamera(camera: Phaser.Cameras.Scene2D.Camera) {
    this.sceneCamera = camera;
  }

  beginMove(pointer: Phaser.Input.Pointer) {
    if (!this.sceneCamera) return;
    this.startX = pointer.x;
    this.startY = pointer.y;
    this.dragging = true;
    this.timestamp = Date.now();
    this.velocityX = 0;
    this.amplitudeX = 0;
    this.velocityY = 0;
    this.amplitudeY = 0;
  }

  moveCamera(pointer: Phaser.Input.Pointer) {
    if (!this.dragging || !this.sceneCamera) return;

    const now = Date.now();
    const deltaTime = now - this.timestamp;
    this.timestamp = now;

    if (this.settings.horizontalScroll) {
      const deltaX = pointer.x - this.startX;
      this.startX = pointer.x;
      this.velocityX = 0.8 * ((1000 * deltaX) / (1 + deltaTime)) + 0.2 * this.velocityX;
      this.sceneCamera.scrollX -= deltaX;
    }

    if (this.settings.verticalScroll) {
      const deltaY = pointer.y - this.startY;
      this.startY = pointer.y;
      this.velocityY = 0.8 * ((1000 * deltaY) / (1 + deltaTime)) + 0.2 * this.velocityY;
      this.sceneCamera.scrollY -= deltaY;
    }
  }

  endMove() {
    if (!this.sceneCamera) return;
    this.dragging = false;
    this.autoScrollX = Math.abs(this.velocityX) > 10;
    this.autoScrollY = Math.abs(this.velocityY) > 10;

    if (this.autoScrollX) {
      this.amplitudeX = 0.8 * this.velocityX;
      this.targetX = Math.round(this.sceneCamera.scrollX - this.amplitudeX);
    }

    if (this.autoScrollY) {
      this.amplitudeY = 0.8 * this.velocityY;
      this.targetY = Math.round(this.sceneCamera.scrollY - this.amplitudeY);
    }

    this.timestamp = Date.now();
  }

  update() {
    if (!this.sceneCamera) return;
    const elapsed = Date.now() - this.timestamp;

    if (this.autoScrollX && this.amplitudeX !== 0) {
      const deltaX = -this.amplitudeX * Math.exp(-elapsed / this.settings.timeConstantScroll);
      if (Math.abs(deltaX) > 10) {
        this.sceneCamera.scrollX = this.targetX - deltaX;
      } else {
        this.autoScrollX = false;
        this.sceneCamera.scrollX = this.targetX;
      }
    }

    if (this.autoScrollY && this.amplitudeY !== 0) {
      const deltaY = -this.amplitudeY * Math.exp(-elapsed / this.settings.timeConstantScroll);
      if (Math.abs(deltaY) > 10) {
        this.sceneCamera.scrollY = this.targetY - deltaY;
      } else {
        this.autoScrollY = false;
        this.sceneCamera.scrollY = this.targetY;
      }
    }
  }

  configure(options: any) {
    if (options) {
      for (const key in options) {
        if (Object.prototype.hasOwnProperty.call(this.settings, key)) {
          this.settings[key as keyof typeof this.settings] = options[key] as never;
        }
      }
    }
  }
  //
  // start() {
  //   const input = this.scene.input;
  //   input.on('pointerdown', this.beginMove, this);
  //   input.on('pointermove', this.moveCamera, this);
  //   input.on('pointerup', this.endMove, this);
  // }
}

import { getEnv } from 'src/utils/envUtils';
import { encryptToken } from 'src/utils/tokenUtils';
import { generateRandomString } from 'src/utils/utils';
import { parseToJson } from 'src/utils/JsonUtils';

export class ApiError extends Error {
  public status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

class FetchService {
  private readonly baseUrl: string;

  constructor() {
    this.baseUrl = getEnv('API_BASE_URL');
  }

  private async request<T>(endpoint: string, options: RequestInit = {}): Promise<T> {
    const token = await encryptToken({
      secret: getEnv('TOKEN_SECRET'),
      timeStamp: new Date().valueOf(),
      platform: 'WEB',
    });
    const nonce = await encryptToken(`${generateRandomString(20)}_${new Date().valueOf()}`);
    const headers = new Headers({
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      nonce,
    });
    Object.entries(options.headers || {}).forEach(([key, value]) => {
      headers.set(key, value);
    });
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      ...options,
      headers,
    });
    const responseCopy = response.clone();
    const data: T = await response.json();
    if (!response.ok) {
      const errorText = await responseCopy.text();
      let errorMessage = 'Something went wrong';
      try {
        const errorData = parseToJson(errorText);
        errorMessage = (errorData as { error?: string })?.error || errorMessage;
      } catch {
        /**/
      }
      throw new ApiError(errorMessage, response.status);
    }
    return data;
  }

  public get<T>(endpoint: string, headers: HeadersInit = {}): Promise<T> {
    return this.request<T>(endpoint, { method: 'GET', headers });
  }

  public async post<T>(endpoint: string, body: any, headers: HeadersInit = {}): Promise<T> {
    return this.request<T>(endpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({ data: body.data }),
    });
  }

  public delete<T>(endpoint: string, body?: any, headers: HeadersInit = {}): Promise<T> {
    const options: RequestInit = {
      method: 'DELETE',
      headers,
    };
    if (body) options.body = JSON.stringify(body);
    return this.request<T>(endpoint, options);
  }

  public put<T>(endpoint: string, body: any, headers: HeadersInit = {}): Promise<T> {
    return this.request<T>(endpoint, {
      method: 'PUT',
      headers,
      body: JSON.stringify(body),
    });
  }
}

export const fetchService = new FetchService();

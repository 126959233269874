import { useEffect, useRef, useState } from 'react';
import Phaser from 'phaser';
import '../../App.css';
import LoadingSpinner from 'src/components/loading/LoadingSpinner';
import { Play } from "src/pages/CardMemory/play";
import { Preloader } from "src/pages/CardMemory/Preloader";

function CardMemory() {
  const cardGame = useRef<Phaser.Game | null>(null);
  const cardGameContainer = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const config = {
      title: 'Card Memory Game',
      type: Phaser.AUTO,
      backgroundColor: "#192a56",
      width: 549,
      height: 480,
      parent: cardGameContainer.current || undefined,
      render: {
        pixelArt: true,
      },
      scale: {
        mode: Phaser.Scale.ScaleModes.FIT,
      },
      scene: [
        Preloader,
        Play
      ]
    };
    cardGame.current = new Phaser.Game(config);
    return () => {
      cardGame.current?.destroy(true);
    };
  }, []);
  return (
    <div className="main-content">
      <LoadingSpinner isLoading={isLoading} />
      <div ref={cardGameContainer} className="game-canvas" />
    </div>
  );
}

export default CardMemory;

import { tokenPayload } from 'src/interfaces';
import { getEnv } from 'src/utils/envUtils';

function pemToArrayBuffer(pem: string) {
  // Remove the PEM header and footer
  const b64Lines = pem.replace(/-----(BEGIN|END) PUBLIC KEY-----/g, '').trim();
  // Decode the base64 string into a binary string
  const b64 = window.atob(b64Lines);
  // Convert the binary string to an ArrayBuffer
  const buf = new ArrayBuffer(b64.length);
  const byteArray = new Uint8Array(buf);
  for (let i = 0; i < b64.length; i++) {
    byteArray[i] = b64.charCodeAt(i);
  }
  return buf;
}
async function encryptWithWebCrypto(publicKey: BufferSource, data: any) {
  const importedPublicKey = await window.crypto.subtle.importKey(
    'spki',
    publicKey,
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
    },
    false,
    ['encrypt'],
  );

  return await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
    },
    importedPublicKey,
    new TextEncoder().encode(data),
  );
}

function arrayBufferToBase64(buffer: ArrayBufferLike): string {
  const byteArray = new Uint8Array(buffer);
  let binary = '';
  byteArray.forEach((byte) => {
    binary += String.fromCharCode(byte);
  });
  return window.btoa(binary);
}

export async function encryptToken(message: tokenPayload | string) {
  try {
    const publicKey = getEnv('PUBLIC_KEY');
    const goodKey = pemToArrayBuffer(publicKey);
    const validMessage = typeof message === 'string' ? message : JSON.stringify(message);
    const result = await encryptWithWebCrypto(goodKey, validMessage);
    return arrayBufferToBase64(result);
  } catch (error) {
    console.error(`encryptToken error=${JSON.stringify(error)}`);
    throw new Error(`encryptToken Status: ${error}.`);
  }
}

import React from 'react';
import { Link } from 'react-router-dom';
import 'src/style/ErrorPage.css';

function ErrorPage() {
  return (
    <div className="error-page">
      <h1>Oops!</h1>
      <h2>404 - Page Not Found</h2>
      <p>The page you are looking for doesn&#39;t exist or an error occurred.</p>
      <Link to="/" className="home-link">
        Go Back to Home
      </Link>
    </div>
  );
}

export default ErrorPage;

import Phaser from 'phaser';
import { soundType } from 'src/pages/dressUp/types';
import ItemManager from 'src/pages/dressUp/ItemManager';
import KineticScrollingRemakePlugin from 'src/pages/dressUp/KineticScrollingRemakePlugin';
import { addQrCodeToImage } from 'src/utils/imageUtils';
import { resetReceipt } from 'src/utils/purchaseUtils';
import { EmptyImage } from 'src/utils/emptyImage';

export default class ControlsManager {
  private scene: Phaser.Scene;
  private readonly kineticScrolling: KineticScrollingRemakePlugin;
  private readonly controls: Phaser.Types.Input.Keyboard.CursorKeys | undefined;
  private readonly space: Phaser.Input.Keyboard.Key | undefined;
  private readonly shift: Phaser.Input.Keyboard.Key | undefined;
  private readonly enter: Phaser.Input.Keyboard.Key | undefined;
  private readonly esc: Phaser.Input.Keyboard.Key | undefined;
  private leftArrow: Phaser.GameObjects.Sprite | undefined;
  private rightArrow: Phaser.GameObjects.Sprite | undefined;
  private resetButton: Phaser.GameObjects.Sprite | undefined;
  private screenshotButton: Phaser.GameObjects.Sprite | undefined;
  private clickSound: soundType;
  cameraSound: soundType;
  private women: Phaser.GameObjects.Group;
  private itemManager: ItemManager;

  constructor(
    scene: Phaser.Scene,
    kineticScrollingPlugin: KineticScrollingRemakePlugin,
    clickSound: soundType,
    cameraSound: soundType,
    womenGroup: Phaser.GameObjects.Group,
    itemManager: ItemManager,
  ) {
    this.scene = scene;
    this.kineticScrolling = kineticScrollingPlugin;
    this.controls = scene.input.keyboard?.createCursorKeys();
    this.space = scene.input.keyboard?.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
    this.shift = scene.input.keyboard?.addKey(Phaser.Input.Keyboard.KeyCodes.SHIFT);
    this.enter = scene.input.keyboard?.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);
    this.esc = scene.input.keyboard?.addKey(Phaser.Input.Keyboard.KeyCodes.ESC);
    this.clickSound = clickSound;
    this.cameraSound = cameraSound;
    this.women = womenGroup;
    this.itemManager = itemManager;
  }

  public getControls() {
    return this.controls;
  }

  public getSpace() {
    return this.space;
  }

  public getShift() {
    return this.shift;
  }

  public getEnter() {
    return this.enter;
  }

  public getEsc() {
    return this.esc;
  }
  async doTakeScreenshot(qrText: string, cd: (base64: string) => void): Promise<void> {
    const renderTexture = this.scene.add.renderTexture(
      0,
      0,
      this.scene.scale.width,
      this.scene.scale.height,
    );

    // Set the background color to white
    renderTexture.fill(0xffffff, 1);

    // Helper function to add each object to the RenderTexture
    const addGameObjectToTexture = (obj: Phaser.GameObjects.GameObject) => renderTexture.draw(obj);

    // Process each object in the array
    this.women.getChildren().forEach((child) => {
      addGameObjectToTexture(child as Phaser.GameObjects.GameObject);
    });

    // Take a snapshot of the RenderTexture
    renderTexture.snapshot((image: HTMLImageElement | Phaser.Display.Color) => {
      if (image instanceof HTMLImageElement) {
        // Create a canvas and set dimensions
        const screenshotCanvas = document.createElement('canvas');
        screenshotCanvas.width = image.width;
        screenshotCanvas.height = image.height;
        const context = screenshotCanvas.getContext('2d');
        if (context) {
          // Draw the image onto the canvas
          context.drawImage(image, 0, 0);
          // Convert the canvas content to a base64-encoded data URL
          const base64Image = screenshotCanvas.toDataURL('image/png');
          addQrCodeToImage(base64Image, qrText).then((barcodeImage) => {
            cd(barcodeImage as string);
          });
        }
      } else {
        addQrCodeToImage(EmptyImage, qrText).then((barcodeImage) => {
          cd(barcodeImage as string);
        });
      }
      // Cleanup
      renderTexture.destroy();
    });
  }
  getCameraButton() {
    return this.screenshotButton;
  }
  renderControls() {
    // Arrows and button configuration
    this.screenshotButton = this.scene.add
      .sprite(this.scene.scale.width - 70, 670, 'dressup', 'button/13.png')
      .setInteractive({ cursor: 'pointer' });
    this.resetButton = this.scene.add
      .sprite(65, 570, 'dressup', 'button/12.png')
      .setInteractive({ cursor: 'pointer' });
    this.leftArrow = this.scene.add
      .sprite(50, 930, 'dressup', 'button/15.png')
      .setInteractive({ cursor: 'pointer' })
      .setDepth(100);
    this.rightArrow = this.scene.add
      .sprite(this.scene.scale.width - 50, 930, 'dressup', 'button/14.png')
      .setInteractive({ cursor: 'pointer' })
      .setDepth(100);

    // // Event Listeners
    // this.screenshotButton.on('pointerdown', () => {
    //   this.cameraSound.play();
    //   this.takeScreenshot();
    // });
    this.resetButton.on('pointerdown', () => this.resetScene());
    this.leftArrow.on('pointerdown', () => this.moveLeft(this.leftArrow));
    this.rightArrow.on('pointerdown', () => this.moveRight(this.rightArrow));
  }
  resetScene() {
    this.clickSound.play();
    this.scene.tweens.add({
      targets: this.women.getChildren(),
      alpha: 0,
      duration: 500,
      ease: 'Linear',
      onComplete: () => {
        this.women.clear(true, true);
        (this.women as any).start();
      },
    });
    this.itemManager.clearItems();
    resetReceipt();
  }
  afterMove() {
    this.resetButton?.setScale(0.8);
    this.screenshotButton?.setScale(0.8);
    this.rightArrow?.setScale(1);
    this.leftArrow?.setScale(1);
    this.scene.tweens.add({
      targets: this.women.getChildren(),
      alpha: 1,
      duration: 500,
      ease: 'Linear',
    });
  }

  moveRight(clickedItem: Phaser.GameObjects.Sprite | undefined) {
    this.kineticScrolling.configure({ horizontalScroll: false, kineticMovement: false });
    this.clickSound.play();

    const moveDistance = 646 / this.itemManager.visibleItems;

    // Calculate total width of items in the container
    const totalItemsWidth = moveDistance * this.itemManager.itemContainer.length;

    // Calculate the left boundary, considering container width to show last item fully
    const leftBoundary = this.scene.scale.width - totalItemsWidth + moveDistance;

    // Tween the container's x position for smooth movement
    this.scene.tweens.add({
      targets: this.itemManager.itemContainer,
      x: Math.max(this.itemManager.itemContainer.x - moveDistance, leftBoundary),
      duration: 500,
      ease: 'Linear',
    });

    clickedItem?.setScale(1.2);
    this.scene.time.delayedCall(200, this.afterMove.bind(this));
  }

  moveLeft(clickedItem: Phaser.GameObjects.Sprite | undefined) {
    this.kineticScrolling.configure({ horizontalScroll: false, kineticMovement: false });
    this.clickSound.play();

    const moveDistance = 646 / this.itemManager.visibleItems;

    // Tween the container's x position for smooth movement
    this.scene.tweens.add({
      targets: this.itemManager.itemContainer,
      x: Math.min(this.itemManager.itemContainer.x + moveDistance, 0),
      duration: 500,
      ease: 'Linear',
    });

    clickedItem?.setScale(1.2);
    this.scene.time.delayedCall(200, this.afterMove.bind(this));
  }
}
